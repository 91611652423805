@use 'sass:math';

@media (max-width: 700px) {
	.mobile-menu {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 90vh;
		padding: 0 1rem;
		background-color: var(--greydark);
		opacity: 0;
		overflow-y: hidden;
		transition: opacity 400ms ease-in-out;
		z-index: -1;

		&.open {
			opacity: 1;
			z-index: 3;
		}

		.mobile-menu-links {
			flex-direction: column-reverse;
			align-items: center;
			display: flex;
			justify-content: center;
			flex: 3;

			li {
				margin-bottom: 1rem;
			}

			.mobile-link {
				height: 2.7rem;
				font-size: 2.3rem;
				font-weight: bold;
				text-transform: uppercase;
				opacity: 1;
				transition: opacity 300ms ease-in-out !important;
			}
		}

		.mobile-menu-footer {
			display: flex;
			flex: 0.5;
			flex-direction: column;
			width: 100%;
			text-align: center;
			text-transform: uppercase;

			p:not(.credentials) {
				padding-bottom: 1rem;
			}
		}
	}
}

@media (min-width: 700px) {
	.mobile-menu {
		display: none;
	}

	// .nav-item__active::after {
	// 	content: '';
	// 	width: 100%;
	// 	height: 0.5px;
	// 	position: absolute;
	// 	top: 100%;
	// 	left: 0;
	// 	background: var(--cultured);
	// }
}

@keyframes apparition {
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

