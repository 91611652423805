@media (max-width: 700px) {
  .mobile-menu {
    width: 100%;
    height: 90vh;
    background-color: var(--greydark);
    opacity: 0;
    z-index: -1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    transition: opacity .4s ease-in-out;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: hidden;
  }

  .mobile-menu.open {
    opacity: 1;
    z-index: 3;
  }

  .mobile-menu .mobile-menu-links {
    flex-direction: column-reverse;
    flex: 3;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .mobile-menu .mobile-menu-links li {
    margin-bottom: 1rem;
  }

  .mobile-menu .mobile-menu-links .mobile-link {
    height: 2.7rem;
    text-transform: uppercase;
    opacity: 1;
    font-size: 2.3rem;
    font-weight: bold;
    transition: opacity .3s ease-in-out !important;
  }

  .mobile-menu .mobile-menu-footer {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    flex-direction: column;
    flex: .5;
    display: flex;
  }

  .mobile-menu .mobile-menu-footer p:not(.credentials) {
    padding-bottom: 1rem;
  }
}

@media (min-width: 700px) {
  .mobile-menu {
    display: none;
  }
}

@keyframes apparition {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*# sourceMappingURL=index.c8fe915a.css.map */
